import {
  Button,
  CircularProgress,
  Grid,
  Pagination,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import MiniDrawer from "../../Components/Drawer";
import { getApiData } from "../../Apis/apiHelper";
import BaseSetting from "../../Apis/setting";
import { toast } from "react-toastify";
import CModal from "../../Components/CModal";
import { Edit } from "@mui/icons-material";
import BaseColor from "../../Config/Color";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import _, { indexOf, isArray, isEmpty, isNull } from "lodash";
import CSelect from "../../Components/CSelect";
import CInput from "../../Components/CInput";
import CButton from "../../Components/CButton";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import { downloadOptions } from "../../Config/staticData";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { FontFamily } from "../../Config/theme";
import TagsInput from "../../Components/TagsInput";
import { Editor } from "@tinymce/tinymce-react";

export default function Category() {
  // ref
  const searchRef = useRef();
  const editorRefApp = useRef();

  // category Data
  const [categoryData, setCategoryData] = useState([]);
  const [parentCategoryData, setParentCategoryData] = useState([]);
  const [isEditModal, setIsEditModal] = useState(false);
  const [editId, setEditId] = useState();
  const [categoryTitle, setCategoryTitle] = useState("");
  const [categoryUrl, setCategoryUrl] = useState("");
  const [categoryDescription, setCategoryDescription] = useState("");
  const [categoryMetaKey, setCategoryMetaKey] = useState("");
  const [categoryTitleErrorText, setCategoryTitleErrorText] = useState("");
  const [categoryDescriptionErrorText, setCategoryDiscriptionErrorText] =
    useState("");
  const [categoryMetaKeyErrorText, setCategoryMetaKeyErrorText] = useState("");
  const [description, setDescription] = useState("");
  const [createCategoryModal, setCreateCategoryModal] = useState(false);
  const [name, setName] = useState("");
  const [parentId, setParentId] = useState(null);
  const [disableBtn, setDisableBtn] = useState(false);
  const [createBtnLoading, setCreateBtnLoading] = useState(false);
  const [tag, setTag] = useState([]);

  // validation
  const [nameErrorText, setNameErrorText] = useState("");
  const [pagination, setPagination] = useState({});
  const [pageLoad, setPageLoad] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [sortingMode, setSortingMode] = useState(false);
  const [viewMoreModal, setViewMoreModal] = useState();

  const [showData, setShowData] = useState("");
  const [rowChange, setRowChange] = useState(false);
  const [selectFileType, setSelectFileType] = useState("");
  const [visibleColumns, setVisibleColumns] = useState([
    "No",
    "Name",
    "Category",
    "Meta title",
    "Meta url",
    "Meta description",
    "Meta keywords",
    "Description",
    "Post count",
  ]);
  const [searchVal, setSearchVal] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  // search params
  const isPage = searchParams.get("page");
  const page = isPage > 1 ? Number(isPage) : 1;
  const search = searchParams.has("search") ? searchParams.get("search") : "";
  const sortVal = searchParams.has("sort") ? searchParams.get("sort") : "";
  const sortByVal = searchParams.has("sort_by")
    ? searchParams.get("sort_by")
    : "";
  const downloadId = searchParams.has("download_id")
    ? searchParams.get("download_id")
    : [];

  const downloadIdArr = searchParams.has("download_id")
    ? searchParams
        .get("download_id")
        ?.split(",")
        .map((str) => parseInt(str, 10))
    : [];

  const [selIds, setselIds] = useState(downloadIdArr);

  const dataGridRef = useRef();
  const getDataGridApi = (api) => {
    dataGridRef.current = api;
  };

  useEffect(() => {
    setHeaderParams(page, search, sortVal, sortByVal, downloadId);
    setTimeout(() => {
      setRowChange(true);
    }, 500);
  }, []);
  useEffect(() => {
    setPageLoad(true);
    getCategoryData(page, search, sortVal, sortByVal, downloadId);
    getParentCategory();
  }, []);

  // set data to URL params
  const setHeaderParams = (
    page,
    search = "",
    sort = "",
    sortBy = "",
    download_id = []
  ) => {
    const params = {
      page: page,
    };

    setPageNo(page);
    if (!_.isEmpty(search)) {
      setSearchVal(search?.trim());
    }
    if (!_.isEmpty(search)) {
      params.search = search;
    }
    if (!_.isEmpty(sort)) {
      params.sort = sort;
    }
    if (!_.isEmpty(sortBy)) {
      params.sort_by = sortBy;
    }
    if (!_.isEmpty(download_id)) {
      params.download_id = download_id;
    }
    setSearchParams(createSearchParams(params));
  };

  const allErrorFalse = () => {
    setNameErrorText("");
    setCategoryTitleErrorText("");
    setCategoryMetaKeyErrorText("");
    setCategoryDiscriptionErrorText("");
  };

  //validation
  const createCategoryValidation = (isUpdate) => {
    if (name?.trim() === "") {
      window.scrollTo(0, 0);
      allErrorFalse();
      setNameErrorText("Category name is required");
    } else if (categoryTitle?.trim() === "") {
      window.scrollTo(0, 0);
      allErrorFalse();
      setCategoryTitleErrorText("Title is required");
    } else if (categoryUrl?.trim() === "") {
      window.scrollTo(0, 0);
      allErrorFalse();
      setCategoryTitleErrorText("Url is required");
      
    } else if (
      categoryDescription?.trim() === "" ||
      isNull(categoryDescription)
    ) {
      window.scrollTo(0, 0);
      allErrorFalse();
      setCategoryDiscriptionErrorText("Description is required");
    } else if (isEmpty(tag)) {
      window.scrollTo(0, 0);
      allErrorFalse();
      setCategoryMetaKeyErrorText("Meta keywords is required");
    } else {
      allErrorFalse();
      createAndUpdateCategoryApi(isUpdate);
    }
  };

  // create update category api call
  function createAndUpdateCategoryApi(isUpdate) {
    setDisableBtn(true);
    setCreateBtnLoading(true);

    const endpoint = isUpdate
      ? BaseSetting.endpoint.updateCategory
      : BaseSetting.endpoint.createcategory;

    let data = {
      name: name,
      parent_id: parentId?.id,
      title: categoryTitle,
      url : categoryUrl,
      description: categoryDescription,
      meta_keys: tag,
      meta_description: description,
    };
    if (isUpdate) {
      data.id = editId;
    }
    console.log("==>>data",data);
    
    getApiData(endpoint, "POST", data)
      .then((result) => {
        if (result?.status) {
          toast(result?.message, { type: "success" });
          setIsEditModal(false);
          getCategoryData(pageNo);
          setName("");
          setTag([]);
          setCategoryDescription("");
          setCategoryTitle("");
          setCategoryUrl("");
          setCategoryMetaKey("");
          setDescription("");
          setParentId(null);
          setCreateCategoryModal(false);
          setDisableBtn(false);
          setCreateBtnLoading(false);
        } else {
          toast(result?.message, { type: "error" });
          setDisableBtn(false);
          setCreateBtnLoading(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setDisableBtn(false);
        setCreateBtnLoading(false);
      });
  }

  //category list
  function getCategoryData(page, search, sort, sortBy) {
    const data = {
      page: page,
      type: "admin",
      download: false,
    };

    if (!_.isEmpty(search)) {
      data.searchVal = search?.trim();
    }

    if (!_.isEmpty(sort)) {
      data.sort = sort;
    }

    if (!_.isEmpty(sortBy)) {
      data.sort_by = sortBy;
    }

    getApiData(BaseSetting.endpoint.allCategory, "post", data)
      .then((result) => {
        if (result?.status) {
          const response = result?.data;
          console.log("response -->.",response);
          
          if (!_.isEmpty(response)) {
            setCategoryData(response?.categories);
            setPagination(response?.pagination);
          }
          toast(result?.message, { type: "success" });
          setTimeout(() => {
            setPageLoad(false);
            setSortingMode(false);
          }, 100);
        } else {
          // toast(result?.message, { type: "error" });
          setPageLoad(false);
          setSortingMode(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setPageLoad(false);
        setSortingMode(false);
      });
  }

  //get one category data for edit
  const openModal = (data) => {
    setEditId("");
    setName("");
    setParentId({});
    setDescription(data?.meta_description);
    setCreateCategoryModal(true);
    setIsEditModal(true);
    setEditId(data?.id);
    setName(data?.name);
    setParentId(
      parentCategoryData.find((item) => item?.name === data?.category)
    );
    setTag(
      !_.isEmpty(data?.meta_key) &&
        !_.isUndefined(data?.meta_key) &&
        !_.isNull(data?.meta_key)
        ? data?.meta_key?.split(",")
        : []
    );
    setCategoryDescription(data?.description);
    setCategoryTitle(data?.title);
    setCategoryUrl(data?.url);
    getParentCategory(data);
  };

  //parent category
  const getParentCategory = (parent) => {
    const endpoint = BaseSetting.endpoint.parentCategory;
    getApiData(endpoint, "post", { type: "admin", parent_id: parent?.id })
      .then((result) => {
        if (result?.status) {
          const response = result?.data;
          setParentCategoryData(response);
        } else {
          // toast(result?.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
      });
  };

  // change status
  const changeStatus = (id) => {
    const endpoint = `${BaseSetting.endpoint.changeStatusCategory}`;
    getApiData(endpoint, "post", { categoryId: id })
      .then((result) => {
        if (result?.status) {
          toast(result?.message, { type: "success" });
          getCategoryData(page, search, sortVal, sortByVal, downloadId);
        } else {
          toast(result?.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
      });
  };

  // downloading data in various format
  const downloadAsPdf = (data) => {
    const pdf = new jsPDF({
      marginLeft: 10,
      marginRight: 10,
      format: "a4",
    });
    const tableData = data;
    const headers = visibleColumns;
    const rows = [];

    const headerStyle = {
      fillColor: BaseColor.primary,
    };

    tableData.forEach((rowData) => {
      const row = [];
      for (const key in rowData) {
        row.push(rowData[key]);
      }
      rows.push(row);
    });
    pdf.setFont("Arial", "normal");
    pdf.setFontSize(12);
    const pageWidth = pdf.internal.pageSize.getWidth() - 20;
    const columnWidth = pageWidth / headers.length - 1;
    const marginLeft = 13;
    pdf.autoTable({
      head: [headers],
      headerStyle: headerStyle,
      body: rows,
      columnStyles: headers.reduce((acc, header, index) => {
        acc[index] = { cellWidth: columnWidth };
        return acc;
      }, {}),
      margin: { left: marginLeft },
    });
    pdf.save("Category-Data.pdf");
    setSelectFileType("");
  };

  const handleDownloadCSV = async (data) => {
    const csvData = Papa.unparse(data);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Category-Data.csv";
    link.click();
    URL.revokeObjectURL(url);
    setSelectFileType("");
  };

  const handleDownloadExcel = async (data) => {
    try {
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "Category-Data.xlsx");
      setSelectFileType("");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //Download data
  function getDownloadData(type) {
    let data = {
      download: true,
      download_id: selIds,
    };
    getApiData(BaseSetting.endpoint.allCategory, "post", data)
      .then((result) => {
        if (result?.status) {
          const response = result?.data?.categories;
          let dataSort = [];
          response.map((val, index) => {
            let newObj = {
              No: index + 1,
              Name: val.name,
              "Parent category": val.category,
              "Meta title": val?.title,
              "Meta url" : val?.url,
              "Meta description": val?.description,
              "Meta keywords": val?.meta_key,
              Description: val?.meta_description,
              "Post count": val?.count,
            };
            dataSort.push(newObj);
          });
          if (type === "Download as PDF") {
            downloadAsPdf(dataSort);
          } else if (type === "Download as CSV") {
            handleDownloadCSV(dataSort);
          } else {
            handleDownloadExcel(dataSort);
          }
        } else {
          toast(result?.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
      });
  }

  // Table columns
  const categoryColumns = [
    {
      field: "id",
      headerName: "NO.",
      flex: 0.2,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const newStartIndex = (pageNo - 1) * pagination?.defaultPageSize + 1;
        categoryData.map((data, i) => {
          data.serialNumber = i + newStartIndex;
        });
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {params?.row?.serialNumber}
          </Typography>
        );
      },
    },
    {
      field: "action",
      headerName: "ACTIONS",
      headerAlign: "center",
      flex: 0.7,
      align: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Grid container alignItems={"center"} justifyContent={"center"}>
            <Grid item>
              <Tooltip title="Edit" placement="top" arrow>
                <Button
                  onClick={() => {
                    openModal(params.row);
                  }}
                >
                  <Edit style={{ fontSize: 20 }} />
                </Button>
              </Tooltip>
            </Grid>
            <Grid item>
              <Switch
                checked={params?.row?.status === "1" ? true : false}
                onChange={() => changeStatus(params?.row?.id)}
              />
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: "name",
      headerName: "NAME",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "category",
      headerName: "PARENT CATEGORY",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "title",
      headerName: "META TITLE",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "url",
      headerName: "META URL",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "description",
      headerName: "META DESCRIPTION",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "meta_key",
      headerName: "META KEYWORD",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "meta_description",
      headerName: "Description",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div>
            <div>
              {" "}
              <div
                dangerouslySetInnerHTML={{
                  __html: params?.row?.meta_description?.slice(0, 150),
                }}
              />
              <span
                style={{
                  color: "#000",
                  fontWeight: 700,
                  cursor: "pointer",
                  marginLeft: "3px",
                }}
                onClick={() => {
                  setShowData(params?.row?.meta_description);
                  setViewMoreModal(true);
                }}
              >
                {params?.row?.meta_description?.length <= 150
                  ? ""
                  : !_.isUndefined(params?.row?.meta_description?.length) &&
                    "...Read More"}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      field: "count",
      headerName: "POST COUNT",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
  ];

  const handleInputKeyPress = (e) => {
    if (e.key === "Enter" && categoryMetaKey.trim() !== "") {
      setTag([...tag, categoryMetaKey.trim()]);
      setCategoryMetaKey("");
    }
  };

  const removeTag = (index) => {
    setTag(tag.filter((el, i) => i !== index));
  };

  document.addEventListener("focusin", (e) => {
    if (
      e.target.closest(
        ".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root"
      ) !== null
    ) {
      e.stopImmediatePropagation();
    }
  });

  return (
    <Grid container>
      <MiniDrawer
        header="Category"
        children={
          <>
            <Grid container justifyContent={"space-between"}>
              <Button
                variant="contained"
                onClick={() => {
                  getParentCategory();
                  setIsEditModal(false);
                  setCreateCategoryModal(true);
                }}
              >
                Create category
              </Button>
              <div style={{ display: "flex" }}>
                {!_.isEmpty(search) ||
                !_.isEmpty(sortVal) ||
                !_.isEmpty(sortByVal) ||
                !_.isEmpty(downloadId) ||
                !_.isEmpty(selIds) ? (
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (dataGridRef.current) {
                        dataGridRef.current.setFilterModel({
                          items: [],
                          quickFilter: "",
                        });
                      }
                      setHeaderParams(page, "", "", "", []);
                      getCategoryData(page);
                      setselIds([]);
                      setSearchVal("");
                      setSortingMode(true);
                    }}
                  >
                    Clear Filters
                  </Button>
                ) : null}
                <CSelect
                  style={{ marginLeft: "20px" }}
                  placeholder="Choose file format for download"
                  value={selectFileType}
                  data={downloadOptions}
                  onSelect={(val) => {
                    setSelectFileType(val);
                    getDownloadData(val);
                  }}
                />
              </div>
            </Grid>
            <Grid container mt={"30px"}>
              {pageLoad ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "50vh",
                  }}
                >
                  <CircularProgress
                    style={{ color: BaseColor.primary }}
                    size={30}
                  />
                </div>
              ) : (
                <DataGrid
                  disableColumnFilter
                  disableColumnSelector
                  sortModel={
                    sortByVal === "asc" || sortByVal === "desc"
                      ? [
                          {
                            field: sortVal,
                            sort: sortByVal,
                          },
                        ]
                      : []
                  }
                  // ref={gridRef}
                  onSortModelChange={(model) => {
                    if (!_.isEmpty(model)) {
                      setSortingMode(true);
                      setHeaderParams(
                        isPage,
                        search,
                        model[0]?.field,
                        model[0].sort,
                        downloadId
                      );
                      getCategoryData(
                        page,
                        search,
                        model[0]?.field,
                        model[0].sort,
                        downloadId
                      );
                    } else {
                      setHeaderParams(isPage, search, "", "", downloadId);
                      getCategoryData(page, search);
                    }
                  }}
                  rows={categoryData}
                  columns={categoryColumns}
                  disableColumnMenu
                  disableRowSelectionOnClick
                  apiRef={getDataGridApi}
                  sortingMode="server"
                  disableDensitySelector
                  loading={sortingMode}
                  checkboxSelection
                  rowSelectionModel={selIds}
                  keepNonExistentRowsSelected
                  paginationMode="server"
                  onRowSelectionModelChange={(id) => {
                    const downloadIdString = id.join(",");
                    setselIds(id);
                    if (rowChange === true) {
                      setHeaderParams(
                        page,
                        search,
                        sortVal,
                        sortByVal,
                        downloadIdString
                      );
                    }
                  }}
                  hideFooter={isEmpty(selIds)}
                  hideFooterPagination
                  showCellVerticalBorder={true}
                  showColumnVerticalBorder={true}
                  disableSelectionOnClick
                  autoHeight={true}
                  getRowHeight={() => "auto"}
                  style={{ width: "100%" }}
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    columnsPanel: {
                      onChange: (e) => {
                        if (e.target.checked == true) {
                          visibleColumns.push(e.target.name);
                        } else {
                          const index = visibleColumns.indexOf(e.target.name);
                          if (index > -1) {
                            visibleColumns.splice(index, 1);
                          }
                        }
                      },
                    },
                    toolbar: {
                      printOptions: { disableToolbarButton: true },
                      csvOptions: { disableToolbarButton: true },
                      showQuickFilter: true,
                      onChange: (e) => {
                        setSortingMode(true);
                        setSearchVal(e.target.value?.toLowerCase());
                        setHeaderParams(
                          1,
                          e?.target?.value?.toLowerCase(),
                          sortVal,
                          sortByVal,
                          downloadId
                        );
                        if (searchRef.current) clearTimeout(searchRef.current);
                        searchRef.current = setTimeout(() => {
                          getCategoryData(
                            1,
                            e?.target?.value?.toLowerCase(),
                            sortVal,
                            sortByVal
                          );
                        }, 800);
                      },
                    },
                  }}
                  initialState={{
                    filter: {
                      filterModel: {
                        items: [],
                        quickFilterValues: [searchVal],
                      },
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-root button svg.MuiSvgIcon-fontSizeSmall":
                      {
                        display: "none",
                        backgroundColor: "red",
                      },
                    "& .css-dwjt6a-MuiButtonBase-root-MuiIconButton-root": {
                      display: "none",
                    },
                    "&:hover .css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
                      {
                        background: "#674188",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar":
                      {
                        width: "1px",
                        height: "4px",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-track":
                      {
                        background: "#F7EFE5",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
                      {
                        background: "rgba(103, 65, 136, 0.5)",
                        borderRadius: "10px",
                      },
                  }}
                />
              )}
            </Grid>
            {!isEmpty(categoryData) && isArray(categoryData) && (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Pagination
                  count={pagination?.totalPage}
                  page={page}
                  siblingCount={2}
                  onChange={(e, value) => {
                    setPageNo(value);
                    setHeaderParams(
                      value,
                      search,
                      sortVal,
                      sortByVal,
                      downloadId
                    );
                    getCategoryData(value, search, sortVal, sortByVal);
                  }}
                  sx={{ display: pageLoad ? "none" : "block" }}
                />
              </div>
            )}

            <CModal
              visible={createCategoryModal}
              title={isEditModal ? "Update Category" : "Create Category"}
              onClose={() => {
                setCreateCategoryModal(false);
                setName("");
                setCategoryTitle("");
                setCategoryUrl("");
                setCategoryMetaKey("");
                setCategoryDescription("");
                setDescription("");
                setTag([]);
                setCategoryDiscriptionErrorText("");
                setCategoryMetaKeyErrorText("");
                setCategoryTitleErrorText("");
                setParentId(null);
                allErrorFalse();
              }}
              style={{ width: "50%" }}
              children={
                <div style={{ padding: "20px" }}>
                  <Grid container rowSpacing={2}>
                    <Grid item xs={12}>
                      <CInput
                        placeholder="Enter category name here"
                        onChange={(val) => {
                          setName(val);
                          allErrorFalse();
                        }}
                        value={name}
                        sx={{ width: "100%" }}
                        errorMsg={nameErrorText}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <CSelect
                        placeholder="Parent category"
                        fullObj
                        data={parentCategoryData}
                        value={parentId}
                        onSelect={(val) => {
                          setParentId(val);
                        }}
                      />
                      {!isEditModal && (
                        <p style={{ fontSize: "14px" }}>
                          * If you want to add sub category then choose above
                          field
                        </p>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <CInput
                        placeholder="Enter title here"
                        onChange={(val) => {
                          setCategoryTitle(val);
                          allErrorFalse();
                        }}
                        value={categoryTitle}
                        sx={{ width: "100%" }}
                        errorMsg={categoryTitleErrorText}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CInput
                        placeholder="Enter URL here"
                        onChange={(val) => {
                          setCategoryUrl(val);
                          allErrorFalse();
                        }}
                        value={categoryUrl}
                        sx={{ width: "100%" }}
                        errorMsg={categoryTitleErrorText}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CInput
                        placeholder="Enter description here"
                        onChange={(val) => {
                          setCategoryDescription(val);
                          allErrorFalse();
                        }}
                        multiline
                        rows={6}
                        maxRows={6}
                        value={categoryDescription}
                        sx={{ width: "100%" }}
                        errorMsg={categoryDescriptionErrorText}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TagsInput
                        onKeyDown={handleInputKeyPress}
                        value={categoryMetaKey}
                        onChange={(val) => {
                          setCategoryMetaKey(val);
                          allErrorFalse();
                        }}
                        tagList={tag}
                        removeTag={(index) => {
                          removeTag(index);
                        }}
                        errorMsg={categoryMetaKeyErrorText}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <span style={{ fontFamily: FontFamily.Regular }}>
                        Description
                      </span>
                    </Grid>
                    <Grid item xs={12}>
                      <Editor
                        apiKey={BaseSetting?.tinymceEditorApiKey}
                        onInit={(evt, editor) =>
                          (editorRefApp.current = editor)
                        }
                        plugins={"image code"}
                        value={description}
                        init={{
                          height: 500,
                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help wordcount image code",
                          ],
                          toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor | alignleft aligncenter " +
                            "alignright alignjustify | bullist numlist outdent indent image | " +
                            "removeformat | image | code",
                          images_upload_url: `${
                            BaseSetting?.serverUrl +
                            BaseSetting?.endpoint?.imageUpload
                          }`,
                        }}
                        onEditorChange={(content, editor) => {
                          setDescription(content);
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <CButton
                        variant="contained"
                        // onClick={() => name.length !== 0 && createAndUpdateCategoryApi()}
                        onClick={() => {
                          createCategoryValidation(isEditModal);
                        }}
                        style={{ width: "100%" }}
                        disabled={disableBtn}
                        loading={createBtnLoading}
                      >
                        {isEditModal ? "Update" : "Create"} category
                      </CButton>
                    </Grid>
                    <Grid item xs={6} paddingLeft={"10px"}>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setCreateCategoryModal(false);
                          setName("");
                          setParentId(null);
                          allErrorFalse();
                          setCategoryTitle("");
                          setCategoryUrl("");
                          setCategoryMetaKey("");
                          setCategoryDescription("");
                          setDescription("");
                          setTag([]);
                          setCategoryDiscriptionErrorText("");
                          setCategoryMetaKeyErrorText("");
                          setCategoryTitleErrorText("");
                        }}
                        style={{ width: "100%" }}
                        disabled={disableBtn}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              }
            />
            <CModal
              visible={viewMoreModal}
              onClose={() => {
                setViewMoreModal(false);
              }}
              title={"Read more"}
              style={{ width: "50%" }}
              children={
                <div style={{ padding: "20px" }}>
                  <div dangerouslySetInnerHTML={{ __html: showData }} />
                </div>
              }
            />
          </>
        }
      />
    </Grid>
  );
}
